var nord = Object.freeze({
  "colors": {
    "activityBar.activeBackground": "#3b4252",
    "activityBar.activeBorder": "#88c0d0",
    "activityBar.background": "#2e3440",
    "activityBar.dropBackground": "#3b4252",
    "activityBar.foreground": "#d8dee9",
    "activityBarBadge.background": "#88c0d0",
    "activityBarBadge.foreground": "#2e3440",
    "badge.background": "#88c0d0",
    "badge.foreground": "#2e3440",
    "button.background": "#88c0d0ee",
    "button.foreground": "#2e3440",
    "button.hoverBackground": "#88c0d0",
    "button.secondaryBackground": "#434c5e",
    "button.secondaryForeground": "#d8dee9",
    "button.secondaryHoverBackground": "#4c566a",
    "charts.blue": "#81a1c1",
    "charts.foreground": "#d8dee9",
    "charts.green": "#a3be8c",
    "charts.lines": "#88c0d0",
    "charts.orange": "#d08770",
    "charts.purple": "#b48ead",
    "charts.red": "#bf616a",
    "charts.yellow": "#ebcb8b",
    "debugConsole.errorForeground": "#bf616a",
    "debugConsole.infoForeground": "#88c0d0",
    "debugConsole.sourceForeground": "#616e88",
    "debugConsole.warningForeground": "#ebcb8b",
    "debugConsoleInputIcon.foreground": "#81a1c1",
    "debugExceptionWidget.background": "#4c566a",
    "debugExceptionWidget.border": "#2e3440",
    "debugToolBar.background": "#3b4252",
    "descriptionForeground": "#d8dee9e6",
    "diffEditor.insertedTextBackground": "#81a1c133",
    "diffEditor.removedTextBackground": "#bf616a4d",
    "dropdown.background": "#3b4252",
    "dropdown.border": "#3b4252",
    "dropdown.foreground": "#d8dee9",
    "editor.background": "#2e3440",
    "editor.findMatchBackground": "#88c0d066",
    "editor.findMatchHighlightBackground": "#88c0d033",
    "editor.findRangeHighlightBackground": "#88c0d033",
    "editor.focusedStackFrameHighlightBackground": "#5e81ac",
    "editor.foreground": "#d8dee9",
    "editor.hoverHighlightBackground": "#3b4252",
    "editor.inactiveSelectionBackground": "#434c5ecc",
    "editor.inlineValuesBackground": "#4c566a",
    "editor.inlineValuesForeground": "#eceff4",
    "editor.lineHighlightBackground": "#3b4252",
    "editor.lineHighlightBorder": "#3b4252",
    "editor.rangeHighlightBackground": "#434c5e52",
    "editor.selectionBackground": "#434c5ecc",
    "editor.selectionHighlightBackground": "#434c5ecc",
    "editor.stackFrameHighlightBackground": "#5e81ac",
    "editor.wordHighlightBackground": "#81a1c166",
    "editor.wordHighlightStrongBackground": "#81a1c199",
    "editorActiveLineNumber.foreground": "#d8dee9cc",
    "editorBracketHighlight.foreground1": "#8fbcbb",
    "editorBracketHighlight.foreground2": "#88c0d0",
    "editorBracketHighlight.foreground3": "#81a1c1",
    "editorBracketHighlight.foreground4": "#5e81ac",
    "editorBracketHighlight.foreground5": "#8fbcbb",
    "editorBracketHighlight.foreground6": "#88c0d0",
    "editorBracketHighlight.unexpectedBracket.foreground": "#bf616a",
    "editorBracketMatch.background": "#2e344000",
    "editorBracketMatch.border": "#88c0d0",
    "editorCodeLens.foreground": "#4c566a",
    "editorCursor.foreground": "#d8dee9",
    "editorError.border": "#bf616a00",
    "editorError.foreground": "#bf616a",
    "editorGroup.background": "#2e3440",
    "editorGroup.border": "#3b425201",
    "editorGroup.dropBackground": "#3b425299",
    "editorGroupHeader.border": "#3b425200",
    "editorGroupHeader.noTabsBackground": "#2e3440",
    "editorGroupHeader.tabsBackground": "#2e3440",
    "editorGroupHeader.tabsBorder": "#3b425200",
    "editorGutter.addedBackground": "#a3be8c",
    "editorGutter.background": "#2e3440",
    "editorGutter.deletedBackground": "#bf616a",
    "editorGutter.modifiedBackground": "#ebcb8b",
    "editorHint.border": "#ebcb8b00",
    "editorHint.foreground": "#ebcb8b",
    "editorHoverWidget.background": "#3b4252",
    "editorHoverWidget.border": "#3b4252",
    "editorIndentGuide.activeBackground": "#4c566a",
    "editorIndentGuide.background": "#434c5eb3",
    "editorInlayHint.background": "#434c5e",
    "editorInlayHint.foreground": "#d8dee9",
    "editorLineNumber.activeForeground": "#d8dee9",
    "editorLineNumber.foreground": "#4c566a",
    "editorLink.activeForeground": "#88c0d0",
    "editorMarkerNavigation.background": "#5e81acc0",
    "editorMarkerNavigationError.background": "#bf616ac0",
    "editorMarkerNavigationWarning.background": "#ebcb8bc0",
    "editorOverviewRuler.addedForeground": "#a3be8c",
    "editorOverviewRuler.border": "#3b4252",
    "editorOverviewRuler.currentContentForeground": "#3b4252",
    "editorOverviewRuler.deletedForeground": "#bf616a",
    "editorOverviewRuler.errorForeground": "#bf616a",
    "editorOverviewRuler.findMatchForeground": "#88c0d066",
    "editorOverviewRuler.incomingContentForeground": "#3b4252",
    "editorOverviewRuler.infoForeground": "#81a1c1",
    "editorOverviewRuler.modifiedForeground": "#ebcb8b",
    "editorOverviewRuler.rangeHighlightForeground": "#88c0d066",
    "editorOverviewRuler.selectionHighlightForeground": "#88c0d066",
    "editorOverviewRuler.warningForeground": "#ebcb8b",
    "editorOverviewRuler.wordHighlightForeground": "#88c0d066",
    "editorOverviewRuler.wordHighlightStrongForeground": "#88c0d066",
    "editorRuler.foreground": "#434c5e",
    "editorSuggestWidget.background": "#2e3440",
    "editorSuggestWidget.border": "#3b4252",
    "editorSuggestWidget.focusHighlightForeground": "#88c0d0",
    "editorSuggestWidget.foreground": "#d8dee9",
    "editorSuggestWidget.highlightForeground": "#88c0d0",
    "editorSuggestWidget.selectedBackground": "#434c5e",
    "editorSuggestWidget.selectedForeground": "#d8dee9",
    "editorWarning.border": "#ebcb8b00",
    "editorWarning.foreground": "#ebcb8b",
    "editorWhitespace.foreground": "#4c566ab3",
    "editorWidget.background": "#2e3440",
    "editorWidget.border": "#3b4252",
    "errorForeground": "#bf616a",
    "extensionButton.prominentBackground": "#434c5e",
    "extensionButton.prominentForeground": "#d8dee9",
    "extensionButton.prominentHoverBackground": "#4c566a",
    "focusBorder": "#3b4252",
    "foreground": "#d8dee9",
    "gitDecoration.conflictingResourceForeground": "#5e81ac",
    "gitDecoration.deletedResourceForeground": "#bf616a",
    "gitDecoration.ignoredResourceForeground": "#d8dee966",
    "gitDecoration.modifiedResourceForeground": "#ebcb8b",
    "gitDecoration.stageDeletedResourceForeground": "#bf616a",
    "gitDecoration.stageModifiedResourceForeground": "#ebcb8b",
    "gitDecoration.submoduleResourceForeground": "#8fbcbb",
    "gitDecoration.untrackedResourceForeground": "#a3be8c",
    "input.background": "#3b4252",
    "input.border": "#3b4252",
    "input.foreground": "#d8dee9",
    "input.placeholderForeground": "#d8dee999",
    "inputOption.activeBackground": "#5e81ac",
    "inputOption.activeBorder": "#5e81ac",
    "inputOption.activeForeground": "#eceff4",
    "inputValidation.errorBackground": "#bf616a",
    "inputValidation.errorBorder": "#bf616a",
    "inputValidation.infoBackground": "#81a1c1",
    "inputValidation.infoBorder": "#81a1c1",
    "inputValidation.warningBackground": "#d08770",
    "inputValidation.warningBorder": "#d08770",
    "keybindingLabel.background": "#4c566a",
    "keybindingLabel.border": "#4c566a",
    "keybindingLabel.bottomBorder": "#4c566a",
    "keybindingLabel.foreground": "#d8dee9",
    "list.activeSelectionBackground": "#88c0d0",
    "list.activeSelectionForeground": "#2e3440",
    "list.dropBackground": "#88c0d099",
    "list.errorForeground": "#bf616a",
    "list.focusBackground": "#88c0d099",
    "list.focusForeground": "#d8dee9",
    "list.focusHighlightForeground": "#eceff4",
    "list.highlightForeground": "#88c0d0",
    "list.hoverBackground": "#3b4252",
    "list.hoverForeground": "#eceff4",
    "list.inactiveFocusBackground": "#434c5ecc",
    "list.inactiveSelectionBackground": "#434c5e",
    "list.inactiveSelectionForeground": "#d8dee9",
    "list.warningForeground": "#ebcb8b",
    "merge.border": "#3b425200",
    "merge.currentContentBackground": "#81a1c14d",
    "merge.currentHeaderBackground": "#81a1c166",
    "merge.incomingContentBackground": "#8fbcbb4d",
    "merge.incomingHeaderBackground": "#8fbcbb66",
    "minimap.background": "#2e3440",
    "minimap.errorHighlight": "#bf616acc",
    "minimap.findMatchHighlight": "#88c0d0",
    "minimap.selectionHighlight": "#88c0d0cc",
    "minimap.warningHighlight": "#ebcb8bcc",
    "minimapGutter.addedBackground": "#a3be8c",
    "minimapGutter.deletedBackground": "#bf616a",
    "minimapGutter.modifiedBackground": "#ebcb8b",
    "minimapSlider.activeBackground": "#434c5eaa",
    "minimapSlider.background": "#434c5e99",
    "minimapSlider.hoverBackground": "#434c5eaa",
    "notification.background": "#3b4252",
    "notification.buttonBackground": "#434c5e",
    "notification.buttonForeground": "#d8dee9",
    "notification.buttonHoverBackground": "#4c566a",
    "notification.errorBackground": "#bf616a",
    "notification.errorForeground": "#2e3440",
    "notification.foreground": "#d8dee9",
    "notification.infoBackground": "#88c0d0",
    "notification.infoForeground": "#2e3440",
    "notification.warningBackground": "#ebcb8b",
    "notification.warningForeground": "#2e3440",
    "notificationCenter.border": "#3b425200",
    "notificationCenterHeader.background": "#2e3440",
    "notificationCenterHeader.foreground": "#88c0d0",
    "notificationLink.foreground": "#88c0d0",
    "notificationToast.border": "#3b425200",
    "notifications.background": "#3b4252",
    "notifications.border": "#2e3440",
    "notifications.foreground": "#d8dee9",
    "panel.background": "#2e3440",
    "panel.border": "#3b4252",
    "panelTitle.activeBorder": "#88c0d000",
    "panelTitle.activeForeground": "#88c0d0",
    "panelTitle.inactiveForeground": "#d8dee9",
    "peekView.border": "#4c566a",
    "peekViewEditor.background": "#2e3440",
    "peekViewEditor.matchHighlightBackground": "#88c0d04d",
    "peekViewEditorGutter.background": "#2e3440",
    "peekViewResult.background": "#2e3440",
    "peekViewResult.fileForeground": "#88c0d0",
    "peekViewResult.lineForeground": "#d8dee966",
    "peekViewResult.matchHighlightBackground": "#88c0d0cc",
    "peekViewResult.selectionBackground": "#434c5e",
    "peekViewResult.selectionForeground": "#d8dee9",
    "peekViewTitle.background": "#3b4252",
    "peekViewTitleDescription.foreground": "#d8dee9",
    "peekViewTitleLabel.foreground": "#88c0d0",
    "pickerGroup.border": "#3b4252",
    "pickerGroup.foreground": "#88c0d0",
    "progressBar.background": "#88c0d0",
    "quickInputList.focusBackground": "#88c0d0",
    "quickInputList.focusForeground": "#2e3440",
    "sash.hoverBorder": "#88c0d0",
    "scrollbar.shadow": "#00000066",
    "scrollbarSlider.activeBackground": "#434c5eaa",
    "scrollbarSlider.background": "#434c5e99",
    "scrollbarSlider.hoverBackground": "#434c5eaa",
    "selection.background": "#88c0d099",
    "sideBar.background": "#2e3440",
    "sideBar.border": "#3b4252",
    "sideBar.foreground": "#d8dee9",
    "sideBarSectionHeader.background": "#3b4252",
    "sideBarSectionHeader.foreground": "#d8dee9",
    "sideBarTitle.foreground": "#d8dee9",
    "statusBar.background": "#3b4252",
    "statusBar.border": "#3b425200",
    "statusBar.debuggingBackground": "#5e81ac",
    "statusBar.debuggingForeground": "#d8dee9",
    "statusBar.foreground": "#d8dee9",
    "statusBar.noFolderBackground": "#3b4252",
    "statusBar.noFolderForeground": "#d8dee9",
    "statusBarItem.activeBackground": "#4c566a",
    "statusBarItem.errorBackground": "#3b4252",
    "statusBarItem.errorForeground": "#bf616a",
    "statusBarItem.hoverBackground": "#434c5e",
    "statusBarItem.prominentBackground": "#3b4252",
    "statusBarItem.prominentHoverBackground": "#434c5e",
    "statusBarItem.warningBackground": "#ebcb8b",
    "statusBarItem.warningForeground": "#2e3440",
    "tab.activeBackground": "#3b4252",
    "tab.activeBorder": "#88c0d000",
    "tab.activeBorderTop": "#88c0d000",
    "tab.activeForeground": "#d8dee9",
    "tab.border": "#3b425200",
    "tab.hoverBackground": "#3b4252cc",
    "tab.hoverBorder": "#88c0d000",
    "tab.inactiveBackground": "#2e3440",
    "tab.inactiveForeground": "#d8dee966",
    "tab.lastPinnedBorder": "#4c566a",
    "tab.unfocusedActiveBorder": "#88c0d000",
    "tab.unfocusedActiveBorderTop": "#88c0d000",
    "tab.unfocusedActiveForeground": "#d8dee999",
    "tab.unfocusedHoverBackground": "#3b4252b3",
    "tab.unfocusedHoverBorder": "#88c0d000",
    "tab.unfocusedInactiveForeground": "#d8dee966",
    "terminal.ansiBlack": "#3b4252",
    "terminal.ansiBlue": "#81a1c1",
    "terminal.ansiBrightBlack": "#4c566a",
    "terminal.ansiBrightBlue": "#81a1c1",
    "terminal.ansiBrightCyan": "#8fbcbb",
    "terminal.ansiBrightGreen": "#a3be8c",
    "terminal.ansiBrightMagenta": "#b48ead",
    "terminal.ansiBrightRed": "#bf616a",
    "terminal.ansiBrightWhite": "#eceff4",
    "terminal.ansiBrightYellow": "#ebcb8b",
    "terminal.ansiCyan": "#88c0d0",
    "terminal.ansiGreen": "#a3be8c",
    "terminal.ansiMagenta": "#b48ead",
    "terminal.ansiRed": "#bf616a",
    "terminal.ansiWhite": "#e5e9f0",
    "terminal.ansiYellow": "#ebcb8b",
    "terminal.background": "#2e3440",
    "terminal.foreground": "#d8dee9",
    "terminal.tab.activeBorder": "#88c0d0",
    "textBlockQuote.background": "#3b4252",
    "textBlockQuote.border": "#81a1c1",
    "textCodeBlock.background": "#4c566a",
    "textLink.activeForeground": "#88c0d0",
    "textLink.foreground": "#88c0d0",
    "textPreformat.foreground": "#8fbcbb",
    "textSeparator.foreground": "#eceff4",
    "titleBar.activeBackground": "#2e3440",
    "titleBar.activeForeground": "#d8dee9",
    "titleBar.border": "#2e344000",
    "titleBar.inactiveBackground": "#2e3440",
    "titleBar.inactiveForeground": "#d8dee966",
    "tree.indentGuidesStroke": "#616e88",
    "walkThrough.embeddedEditorBackground": "#2e3440",
    "welcomePage.buttonBackground": "#434c5e",
    "welcomePage.buttonHoverBackground": "#4c566a",
    "widget.shadow": "#00000066"
  },
  "displayName": "Nord",
  "name": "nord",
  "semanticHighlighting": true,
  "tokenColors": [
    {
      "settings": {
        "background": "#2e3440ff",
        "foreground": "#d8dee9ff"
      }
    },
    {
      "scope": "emphasis",
      "settings": {
        "fontStyle": "italic"
      }
    },
    {
      "scope": "strong",
      "settings": {
        "fontStyle": "bold"
      }
    },
    {
      "scope": "comment",
      "settings": {
        "foreground": "#616E88"
      }
    },
    {
      "scope": "constant.character",
      "settings": {
        "foreground": "#EBCB8B"
      }
    },
    {
      "scope": "constant.character.escape",
      "settings": {
        "foreground": "#EBCB8B"
      }
    },
    {
      "scope": "constant.language",
      "settings": {
        "foreground": "#81A1C1"
      }
    },
    {
      "scope": "constant.numeric",
      "settings": {
        "foreground": "#B48EAD"
      }
    },
    {
      "scope": "constant.regexp",
      "settings": {
        "foreground": "#EBCB8B"
      }
    },
    {
      "scope": [
        "entity.name.class",
        "entity.name.type.class"
      ],
      "settings": {
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": "entity.name.function",
      "settings": {
        "foreground": "#88C0D0"
      }
    },
    {
      "scope": "entity.name.tag",
      "settings": {
        "foreground": "#81A1C1"
      }
    },
    {
      "scope": "entity.other.attribute-name",
      "settings": {
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": "entity.other.inherited-class",
      "settings": {
        "fontStyle": "bold",
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": "invalid.deprecated",
      "settings": {
        "background": "#EBCB8B",
        "foreground": "#D8DEE9"
      }
    },
    {
      "scope": "invalid.illegal",
      "settings": {
        "background": "#BF616A",
        "foreground": "#D8DEE9"
      }
    },
    {
      "scope": "keyword",
      "settings": {
        "foreground": "#81A1C1"
      }
    },
    {
      "scope": "keyword.operator",
      "settings": {
        "foreground": "#81A1C1"
      }
    },
    {
      "scope": "keyword.other.new",
      "settings": {
        "foreground": "#81A1C1"
      }
    },
    {
      "scope": "markup.bold",
      "settings": {
        "fontStyle": "bold"
      }
    },
    {
      "scope": "markup.changed",
      "settings": {
        "foreground": "#EBCB8B"
      }
    },
    {
      "scope": "markup.deleted",
      "settings": {
        "foreground": "#BF616A"
      }
    },
    {
      "scope": "markup.inserted",
      "settings": {
        "foreground": "#A3BE8C"
      }
    },
    {
      "scope": "meta.preprocessor",
      "settings": {
        "foreground": "#5E81AC"
      }
    },
    {
      "scope": "punctuation",
      "settings": {
        "foreground": "#ECEFF4"
      }
    },
    {
      "scope": [
        "punctuation.definition.method-parameters",
        "punctuation.definition.function-parameters",
        "punctuation.definition.parameters"
      ],
      "settings": {
        "foreground": "#ECEFF4"
      }
    },
    {
      "scope": "punctuation.definition.tag",
      "settings": {
        "foreground": "#81A1C1"
      }
    },
    {
      "scope": [
        "punctuation.definition.comment",
        "punctuation.end.definition.comment",
        "punctuation.start.definition.comment"
      ],
      "settings": {
        "foreground": "#616E88"
      }
    },
    {
      "scope": "punctuation.section",
      "settings": {
        "foreground": "#ECEFF4"
      }
    },
    {
      "scope": [
        "punctuation.section.embedded.begin",
        "punctuation.section.embedded.end"
      ],
      "settings": {
        "foreground": "#81A1C1"
      }
    },
    {
      "scope": "punctuation.terminator",
      "settings": {
        "foreground": "#81A1C1"
      }
    },
    {
      "scope": "punctuation.definition.variable",
      "settings": {
        "foreground": "#81A1C1"
      }
    },
    {
      "scope": "storage",
      "settings": {
        "foreground": "#81A1C1"
      }
    },
    {
      "scope": "string",
      "settings": {
        "foreground": "#A3BE8C"
      }
    },
    {
      "scope": "string.regexp",
      "settings": {
        "foreground": "#EBCB8B"
      }
    },
    {
      "scope": "support.class",
      "settings": {
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": "support.constant",
      "settings": {
        "foreground": "#81A1C1"
      }
    },
    {
      "scope": "support.function",
      "settings": {
        "foreground": "#88C0D0"
      }
    },
    {
      "scope": "support.function.construct",
      "settings": {
        "foreground": "#81A1C1"
      }
    },
    {
      "scope": "support.type",
      "settings": {
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": "support.type.exception",
      "settings": {
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": "token.debug-token",
      "settings": {
        "foreground": "#b48ead"
      }
    },
    {
      "scope": "token.error-token",
      "settings": {
        "foreground": "#bf616a"
      }
    },
    {
      "scope": "token.info-token",
      "settings": {
        "foreground": "#88c0d0"
      }
    },
    {
      "scope": "token.warn-token",
      "settings": {
        "foreground": "#ebcb8b"
      }
    },
    {
      "scope": "variable.other",
      "settings": {
        "foreground": "#D8DEE9"
      }
    },
    {
      "scope": "variable.language",
      "settings": {
        "foreground": "#81A1C1"
      }
    },
    {
      "scope": "variable.parameter",
      "settings": {
        "foreground": "#D8DEE9"
      }
    },
    {
      "scope": "punctuation.separator.pointer-access.c",
      "settings": {
        "foreground": "#81A1C1"
      }
    },
    {
      "scope": [
        "source.c meta.preprocessor.include",
        "source.c string.quoted.other.lt-gt.include"
      ],
      "settings": {
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": [
        "source.cpp keyword.control.directive.conditional",
        "source.cpp punctuation.definition.directive",
        "source.c keyword.control.directive.conditional",
        "source.c punctuation.definition.directive"
      ],
      "settings": {
        "fontStyle": "bold",
        "foreground": "#5E81AC"
      }
    },
    {
      "scope": "source.css constant.other.color.rgb-value",
      "settings": {
        "foreground": "#B48EAD"
      }
    },
    {
      "scope": "source.css meta.property-value",
      "settings": {
        "foreground": "#88C0D0"
      }
    },
    {
      "scope": [
        "source.css keyword.control.at-rule.media",
        "source.css keyword.control.at-rule.media punctuation.definition.keyword"
      ],
      "settings": {
        "foreground": "#D08770"
      }
    },
    {
      "scope": "source.css punctuation.definition.keyword",
      "settings": {
        "foreground": "#81A1C1"
      }
    },
    {
      "scope": "source.css support.type.property-name",
      "settings": {
        "foreground": "#D8DEE9"
      }
    },
    {
      "scope": "source.diff meta.diff.range.context",
      "settings": {
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": "source.diff meta.diff.header.from-file",
      "settings": {
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": "source.diff punctuation.definition.from-file",
      "settings": {
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": "source.diff punctuation.definition.range",
      "settings": {
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": "source.diff punctuation.definition.separator",
      "settings": {
        "foreground": "#81A1C1"
      }
    },
    {
      "scope": "entity.name.type.module.elixir",
      "settings": {
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": "variable.other.readwrite.module.elixir",
      "settings": {
        "fontStyle": "bold",
        "foreground": "#D8DEE9"
      }
    },
    {
      "scope": "constant.other.symbol.elixir",
      "settings": {
        "fontStyle": "bold",
        "foreground": "#D8DEE9"
      }
    },
    {
      "scope": "variable.other.constant.elixir",
      "settings": {
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": "source.go constant.other.placeholder.go",
      "settings": {
        "foreground": "#EBCB8B"
      }
    },
    {
      "scope": "source.java comment.block.documentation.javadoc punctuation.definition.entity.html",
      "settings": {
        "foreground": "#81A1C1"
      }
    },
    {
      "scope": "source.java constant.other",
      "settings": {
        "foreground": "#D8DEE9"
      }
    },
    {
      "scope": "source.java keyword.other.documentation",
      "settings": {
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": "source.java keyword.other.documentation.author.javadoc",
      "settings": {
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": [
        "source.java keyword.other.documentation.directive",
        "source.java keyword.other.documentation.custom"
      ],
      "settings": {
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": "source.java keyword.other.documentation.see.javadoc",
      "settings": {
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": "source.java meta.method-call meta.method",
      "settings": {
        "foreground": "#88C0D0"
      }
    },
    {
      "scope": [
        "source.java meta.tag.template.link.javadoc",
        "source.java string.other.link.title.javadoc"
      ],
      "settings": {
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": "source.java meta.tag.template.value.javadoc",
      "settings": {
        "foreground": "#88C0D0"
      }
    },
    {
      "scope": "source.java punctuation.definition.keyword.javadoc",
      "settings": {
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": [
        "source.java punctuation.definition.tag.begin.javadoc",
        "source.java punctuation.definition.tag.end.javadoc"
      ],
      "settings": {
        "foreground": "#616E88"
      }
    },
    {
      "scope": "source.java storage.modifier.import",
      "settings": {
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": "source.java storage.modifier.package",
      "settings": {
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": "source.java storage.type",
      "settings": {
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": "source.java storage.type.annotation",
      "settings": {
        "foreground": "#D08770"
      }
    },
    {
      "scope": "source.java storage.type.generic",
      "settings": {
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": "source.java storage.type.primitive",
      "settings": {
        "foreground": "#81A1C1"
      }
    },
    {
      "scope": [
        "source.js punctuation.decorator",
        "source.js meta.decorator variable.other.readwrite",
        "source.js meta.decorator entity.name.function"
      ],
      "settings": {
        "foreground": "#D08770"
      }
    },
    {
      "scope": "source.js meta.object-literal.key",
      "settings": {
        "foreground": "#88C0D0"
      }
    },
    {
      "scope": "source.js storage.type.class.jsdoc",
      "settings": {
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": [
        "source.js string.quoted.template punctuation.quasi.element.begin",
        "source.js string.quoted.template punctuation.quasi.element.end",
        "source.js string.template punctuation.definition.template-expression"
      ],
      "settings": {
        "foreground": "#81A1C1"
      }
    },
    {
      "scope": "source.js string.quoted.template meta.method-call.with-arguments",
      "settings": {
        "foreground": "#ECEFF4"
      }
    },
    {
      "scope": [
        "source.js string.template meta.template.expression support.variable.property",
        "source.js string.template meta.template.expression variable.other.object"
      ],
      "settings": {
        "foreground": "#D8DEE9"
      }
    },
    {
      "scope": "source.js support.type.primitive",
      "settings": {
        "foreground": "#81A1C1"
      }
    },
    {
      "scope": "source.js variable.other.object",
      "settings": {
        "foreground": "#D8DEE9"
      }
    },
    {
      "scope": "source.js variable.other.readwrite.alias",
      "settings": {
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": [
        "source.js meta.embedded.line meta.brace.square",
        "source.js meta.embedded.line meta.brace.round",
        "source.js string.quoted.template meta.brace.square",
        "source.js string.quoted.template meta.brace.round"
      ],
      "settings": {
        "foreground": "#ECEFF4"
      }
    },
    {
      "scope": "text.html.basic constant.character.entity.html",
      "settings": {
        "foreground": "#EBCB8B"
      }
    },
    {
      "scope": "text.html.basic constant.other.inline-data",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#D08770"
      }
    },
    {
      "scope": "text.html.basic meta.tag.sgml.doctype",
      "settings": {
        "foreground": "#5E81AC"
      }
    },
    {
      "scope": "text.html.basic punctuation.definition.entity",
      "settings": {
        "foreground": "#81A1C1"
      }
    },
    {
      "scope": "source.properties entity.name.section.group-title.ini",
      "settings": {
        "foreground": "#88C0D0"
      }
    },
    {
      "scope": "source.properties punctuation.separator.key-value.ini",
      "settings": {
        "foreground": "#81A1C1"
      }
    },
    {
      "scope": [
        "text.html.markdown markup.fenced_code.block",
        "text.html.markdown markup.fenced_code.block punctuation.definition"
      ],
      "settings": {
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": "markup.heading",
      "settings": {
        "foreground": "#88C0D0"
      }
    },
    {
      "scope": [
        "text.html.markdown markup.inline.raw",
        "text.html.markdown markup.inline.raw punctuation.definition.raw"
      ],
      "settings": {
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": "text.html.markdown markup.italic",
      "settings": {
        "fontStyle": "italic"
      }
    },
    {
      "scope": "text.html.markdown markup.underline.link",
      "settings": {
        "fontStyle": "underline"
      }
    },
    {
      "scope": "text.html.markdown beginning.punctuation.definition.list",
      "settings": {
        "foreground": "#81A1C1"
      }
    },
    {
      "scope": "text.html.markdown beginning.punctuation.definition.quote",
      "settings": {
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": "text.html.markdown markup.quote",
      "settings": {
        "foreground": "#616E88"
      }
    },
    {
      "scope": "text.html.markdown constant.character.math.tex",
      "settings": {
        "foreground": "#81A1C1"
      }
    },
    {
      "scope": [
        "text.html.markdown punctuation.definition.math.begin",
        "text.html.markdown punctuation.definition.math.end"
      ],
      "settings": {
        "foreground": "#5E81AC"
      }
    },
    {
      "scope": "text.html.markdown punctuation.definition.function.math.tex",
      "settings": {
        "foreground": "#88C0D0"
      }
    },
    {
      "scope": "text.html.markdown punctuation.math.operator.latex",
      "settings": {
        "foreground": "#81A1C1"
      }
    },
    {
      "scope": "text.html.markdown punctuation.definition.heading",
      "settings": {
        "foreground": "#81A1C1"
      }
    },
    {
      "scope": [
        "text.html.markdown punctuation.definition.constant",
        "text.html.markdown punctuation.definition.string"
      ],
      "settings": {
        "foreground": "#81A1C1"
      }
    },
    {
      "scope": [
        "text.html.markdown constant.other.reference.link",
        "text.html.markdown string.other.link.description",
        "text.html.markdown string.other.link.title"
      ],
      "settings": {
        "foreground": "#88C0D0"
      }
    },
    {
      "scope": "source.perl punctuation.definition.variable",
      "settings": {
        "foreground": "#D8DEE9"
      }
    },
    {
      "scope": [
        "source.php meta.function-call",
        "source.php meta.function-call.object"
      ],
      "settings": {
        "foreground": "#88C0D0"
      }
    },
    {
      "scope": [
        "source.python entity.name.function.decorator",
        "source.python meta.function.decorator support.type"
      ],
      "settings": {
        "foreground": "#D08770"
      }
    },
    {
      "scope": "source.python meta.function-call.generic",
      "settings": {
        "foreground": "#88C0D0"
      }
    },
    {
      "scope": "source.python support.type",
      "settings": {
        "foreground": "#88C0D0"
      }
    },
    {
      "scope": [
        "source.python variable.parameter.function.language"
      ],
      "settings": {
        "foreground": "#D8DEE9"
      }
    },
    {
      "scope": [
        "source.python meta.function.parameters variable.parameter.function.language.special.self"
      ],
      "settings": {
        "foreground": "#81A1C1"
      }
    },
    {
      "scope": "source.rust entity.name.type",
      "settings": {
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": "source.rust meta.macro entity.name.function",
      "settings": {
        "fontStyle": "bold",
        "foreground": "#88C0D0"
      }
    },
    {
      "scope": [
        "source.rust meta.attribute",
        "source.rust meta.attribute punctuation",
        "source.rust meta.attribute keyword.operator"
      ],
      "settings": {
        "foreground": "#5E81AC"
      }
    },
    {
      "scope": "source.rust entity.name.type.trait",
      "settings": {
        "fontStyle": "bold"
      }
    },
    {
      "scope": "source.rust punctuation.definition.interpolation",
      "settings": {
        "foreground": "#EBCB8B"
      }
    },
    {
      "scope": [
        "source.css.scss punctuation.definition.interpolation.begin.bracket.curly",
        "source.css.scss punctuation.definition.interpolation.end.bracket.curly"
      ],
      "settings": {
        "foreground": "#81A1C1"
      }
    },
    {
      "scope": "source.css.scss variable.interpolation",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#D8DEE9"
      }
    },
    {
      "scope": [
        "source.ts punctuation.decorator",
        "source.ts meta.decorator variable.other.readwrite",
        "source.ts meta.decorator entity.name.function",
        "source.tsx punctuation.decorator",
        "source.tsx meta.decorator variable.other.readwrite",
        "source.tsx meta.decorator entity.name.function"
      ],
      "settings": {
        "foreground": "#D08770"
      }
    },
    {
      "scope": [
        "source.ts meta.object-literal.key",
        "source.tsx meta.object-literal.key"
      ],
      "settings": {
        "foreground": "#D8DEE9"
      }
    },
    {
      "scope": [
        "source.ts meta.object-literal.key entity.name.function",
        "source.tsx meta.object-literal.key entity.name.function"
      ],
      "settings": {
        "foreground": "#88C0D0"
      }
    },
    {
      "scope": [
        "source.ts support.class",
        "source.ts support.type",
        "source.ts entity.name.type",
        "source.ts entity.name.class",
        "source.tsx support.class",
        "source.tsx support.type",
        "source.tsx entity.name.type",
        "source.tsx entity.name.class"
      ],
      "settings": {
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": [
        "source.ts support.constant.math",
        "source.ts support.constant.dom",
        "source.ts support.constant.json",
        "source.tsx support.constant.math",
        "source.tsx support.constant.dom",
        "source.tsx support.constant.json"
      ],
      "settings": {
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": [
        "source.ts support.variable",
        "source.tsx support.variable"
      ],
      "settings": {
        "foreground": "#D8DEE9"
      }
    },
    {
      "scope": [
        "source.ts meta.embedded.line meta.brace.square",
        "source.ts meta.embedded.line meta.brace.round",
        "source.tsx meta.embedded.line meta.brace.square",
        "source.tsx meta.embedded.line meta.brace.round"
      ],
      "settings": {
        "foreground": "#ECEFF4"
      }
    },
    {
      "scope": "text.xml entity.name.tag.namespace",
      "settings": {
        "foreground": "#8FBCBB"
      }
    },
    {
      "scope": "text.xml keyword.other.doctype",
      "settings": {
        "foreground": "#5E81AC"
      }
    },
    {
      "scope": "text.xml meta.tag.preprocessor entity.name.tag",
      "settings": {
        "foreground": "#5E81AC"
      }
    },
    {
      "scope": [
        "text.xml string.unquoted.cdata",
        "text.xml string.unquoted.cdata punctuation.definition.string"
      ],
      "settings": {
        "fontStyle": "italic",
        "foreground": "#D08770"
      }
    },
    {
      "scope": "source.yaml entity.name.tag",
      "settings": {
        "foreground": "#8FBCBB"
      }
    }
  ],
  "type": "dark"
});

export { nord as default };
